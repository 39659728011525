@import-normalize;
@import "react-slideshow-image/dist/styles.css";
@import "assets/icons/font/flaticon.css";
@import "assets/icons/others/flaticon.css";

* {
  box-sizing: border-box;
  font-family: "DINPro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

html,
body,
#root {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-tabs-tab {
  font-family: "DINPro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-transform: uppercase;
  font-weight: 500;

  color: #000 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  opacity: 1 !important;
  color: #000 !important;
  font-weight: 700 !important;
}
.ant-tabs-tab:hover,
.ant-tabs-tab-btn:hover {
  color: #000 !important;
}
.ant-tabs-ink-bar {
  background: #ffcc01 !important;
}

.image-gallery-thumbnail:hover,
.image-gallery-thumbnail.active {
  border-color: #ffcc01 !important;
}
.image-gallery-icon:hover {
  color: #ffcc01 !important;
}
