@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?3b20b3f515ac6bcc90a426369602c550") format("truetype"),
url("./flaticon.woff?3b20b3f515ac6bcc90a426369602c550") format("woff"),
url("./flaticon.woff2?3b20b3f515ac6bcc90a426369602c550") format("woff2"),
url("./flaticon.eot?3b20b3f515ac6bcc90a426369602c550#iefix") format("embedded-opentype"),
url("./flaticon.svg?3b20b3f515ac6bcc90a426369602c550#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-competition:before {
    content: "\f101";
}
.flaticon-rating:before {
    content: "\f102";
}
.flaticon-chess-pieces:before {
    content: "\f103";
}
.flaticon-newspaper:before {
    content: "\f104";
}
.flaticon-to-do-list:before {
    content: "\f105";
}
.flaticon-cancel:before {
    content: "\f106";
}
.flaticon-assets:before {
    content: "\f107";
}
.flaticon-process:before {
    content: "\f108";
}
.flaticon-planner:before {
    content: "\f109";
}
.flaticon-save-money:before {
    content: "\f10a";
}
.flaticon-next:before {
    content: "\f10b";
}
.flaticon-left-arrow:before {
    content: "\f10c";
}
.flaticon-right-arrow:before {
    content: "\f10d";
}
.flaticon-left-arrowhead:before {
    content: "\f10e";
}
.flaticon-youtube:before {
    content: "\f10f";
}
.flaticon-email:before {
    content: "\f110";
}
.flaticon-menu:before {
    content: "\f111";
}
.flaticon-play-button:before {
    content: "\f112";
}
.flaticon-teamwork:before {
    content: "\f113";
}
.flaticon-route:before {
    content: "\f114";
}
.flaticon-financial-profit:before {
    content: "\f115";
}
.flaticon-money:before {
    content: "\f116";
}
.flaticon-strategic:before {
    content: "\f117";
}
.flaticon-partner:before {
    content: "\f118";
}
.flaticon-thumb-up:before {
    content: "\f119";
}
.flaticon-woman:before {
    content: "\f11a";
}
.flaticon-health-insurance:before {
    content: "\f11b";
}
.flaticon-coronavirus:before {
    content: "\f11c";
}
.flaticon-cloud-computing:before {
    content: "\f11d";
}
.flaticon-hand-shake:before {
    content: "\f11e";
}
.flaticon-coins:before {
    content: "\f11f";
}
.flaticon-tick:before {
    content: "\f120";
}
.flaticon-paper:before {
    content: "\f121";
}
.flaticon-asset-management:before {
    content: "\f122";
}
