/* regular */
@font-face {
  font-family: "DINPro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("DINPro"), local("DINPro-Regular"),
    url(../assets/fonts/DINPro-Regular.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF;
}

/* regular */
@font-face {
  font-family: "DINPro";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("DINPro"), local("DINPro-Medium"),
    url(../assets/fonts/DINPro-Medium.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

/* bold */
@font-face {
  font-family: "DINPro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("DINPro Bold"), local("DINPro-Bold"),
    url(../assets/fonts/DINPro-Bold.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212;
}
