@font-face {
  font-family: "flaticons";
  src: url("./flaticon.ttf?e6e1e448c158de2ff9f6ee7fde4c18d9") format("truetype"),
    url("./flaticon.woff?e6e1e448c158de2ff9f6ee7fde4c18d9") format("woff"),
    url("./flaticon.woff2?e6e1e448c158de2ff9f6ee7fde4c18d9") format("woff2"),
    url("./flaticon.eot?e6e1e448c158de2ff9f6ee7fde4c18d9#iefix")
      format("embedded-opentype"),
    url("./flaticon.svg?e6e1e448c158de2ff9f6ee7fde4c18d9#flaticon")
      format("svg");
}

i[class^="flaticons-"]:before,
i[class*=" flaticons-"]:before {
  font-family: flaticons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .flaticon-virus:before {
    content: "\f101";
}
.flaticon-tick:before {
    content: "\f102";
}
.flaticon-telephone:before {
    content: "\f103";
} */
.flaticons-warning:before {
  content: "\f104";
}
.flaticons-error:before {
  content: "\f105";
}
.flaticons-error:before {
  content: "\f106";
}
