@import "./colors.scss";

$fontFamily: "DINPro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

$headerHeight: 70px;
$layoutMaxWidth: 90%;

.layoutMaxWidth {
  position: relative;
  margin: 0 auto;
  max-width: $layoutMaxWidth;
  width: 100%;
}

.headerBlocks {
  &.hasLogo {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .containerLogo {
    margin: 2vw 0 1vw;
    width: 173px;
    img {
      max-width: 100%;
    }
  }

  .category,
  .title {
    text-transform: uppercase;
  }

  .category {
    background-color: $red;
    padding: 0.8vw 1.5vw;
    display: inline-block;
    border-radius: 2px;
    color: #fff;
    font-size: 1.1vw;
    margin-bottom: 1vw;
    font-weight: 500;
  }
  .title {
    display: block;
    font-size: 2.3vw;
    line-height: 3vw;
    font-weight: 400;

    strong {
      font-weight: 700;
    }
  }
}

.knowMoreBlock {
  a {
    background-color: $yellow;
    padding: 0.8vw 1.5vw;
    text-transform: uppercase;
    border-radius: 2px;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    font-size: 1.1vw;
  }
}

.noContent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vw;

  span {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.7vw;
    color: #ccc;
  }
}

.error,
.notFount {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.error div,
.notFount div {
  text-align: center;
  padding: 0 10vw;
}
.error .icon,
.notFount .icon {
  font-size: 9vw;
  color: #aaa;
}
.error div .info,
.notFount div .info {
  font-size: 3.5vw;
  color: #aaa;
  display: block;
  cursor: default;
}
.error div .link,
.notFount div .link {
  display: block;
  font-size: 1.8vw;
  font-weight: 500;
  margin-top: 2vw;
  color: #aaa;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .headerBlocks {
    .category {
      padding: 1.8vw 2.8vw;
      font-size: 1.8vw;
    }
    .title {
      font-size: 4vw;
      line-height: 5vw;
    }
  }

  .knowMoreBlock {
    a {
      padding: 1.8vw 2.8vw;
      color: #fff;
      font-weight: 500;
      font-size: 1.8vw;
    }
  }

  .noContent {
    min-height: 40vw;

    span {
      font-size: 2.7vw;
    }
  }
}

@media screen and (max-width: 540px) {
  .headerBlocks {
    .category {
      padding: 2.5vw 3.5vw;
      font-size: 3vw;
      margin-bottom: 1vw;
    }
    .title {
      font-size: 7vw;
      line-height: 8vw;
    }
  }

  .noContent {
    min-height: 50vw;

    span {
      font-size: 4.7vw;
    }
  }

  .knowMoreBlock {
    a {
      padding: 1.5vw 2.5vw;
      font-size: 2.5vw;
    }
  }
}

@media screen and (max-width: 375px) {
  .headerBlocks {
    .category {
      padding: 3vw 4vw;
      font-size: 3.5vw;
      margin-bottom: 1vw;
    }
    .title {
      font-size: 7vw;
      line-height: 8vw;
    }
  }

  .noContent {
    min-height: 70vw;

    span {
      font-size: 5.7vw;
    }
  }

  .knowMoreBlock {
    a {
      padding: 3vw 4.2vw;
      font-size: 3.5vw;
    }
  }
}
